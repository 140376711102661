import { postRequest } from './index'
import { BSERURL } from '@/config'

// 保付子合约签约
export const abcBailPaySubContractSign = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/abc/bailPay/subContractSign`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 开户信息新增
export const cmpBankinfoAdd = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/fund/cmpBankinfo/add`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 账户信息分页
export const accountInfoPage = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/fund/accountInfo/page`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 账户信息分页  不带token的
export const listSettleBankByCmpId = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}fund/accountInfo/listSettleBankByCmpId`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 根据企业编码查询已开户账户信息的结算银行列表
export const fundAccountInfoListSettleBankByCmpId = (data, successCallback, failureCallback) => {
  postRequest(`${BSERURL}/fund/accountInfo/listSettleBankByCmpId`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

