import { postRequest, getRequest } from './index'
import { SRCURL } from '@/config'
// 订单-买家-提交订单
export const tradeOrderSave = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/order/save`, data, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 订单-购物车下单商品详情
export const tradeOrderCartDetail = (id, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/order/cart/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 订单-立即购买商品详情
export const tradeOrderGoodsDetail = (id, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/order/goods/detail/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 订单-买家-支付订单
export const tradeOrdePayOrder = (data, successCallback, failureCallback) => {
  postRequest(`${SRCURL}/trade/order/payOrder/${data.orderId}?orderTotalAmount=${data.orderTotalAmount}&settleBankId=${data.settleBankId}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}

// 订单-卖家-订单概况
export const getOrderSellerSummary = (successCallback, failureCallback) => {
  getRequest(SRCURL + '/trade/order/getOrderSellerSummary', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单-买家-订单概况
export const getOrderBuyerSummary = (successCallback, failureCallback) => {
  getRequest(SRCURL + '/trade/order/getOrderBuyerSummary', {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
// 订单-获取支付链接
export const tradeOrderPayUrl = (id, successCallback, failureCallback) => {
  getRequest(`${SRCURL}/trade/order/payUrl/${id}`, {}, res => (
    successCallback(res)
  ), error => {
    if (failureCallback)failureCallback(error)
  })
}
